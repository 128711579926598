import React from 'react'
import { graphql, Link } from 'gatsby'

import SEO from '../components/seo'

import Breadcrumb from '../components/Breadcrumb'
import { Boom, Content } from './page'
import Layout from '../components/layout'
import Arrow from '../components/Arrow'

import styled from 'styled-components'
import { backgroundImage } from '../pages/case_studies'

export const Wrapper = styled.section`
  max-width: 1200px;
  margin: 0 auto;

  display: flex;

  padding: 2rem 0;
  min-height: 45vh;

  .wpcf7-form {
    label {
      display: block;

      span {
        display: block;
      }
    }
  }
`

export const getImage = image => {
  if (image) return image.source_url
  return ''
}

export default ({ data }) => {
  const post = data.wordpressPost

  const relatedPostsMarkup = data.allWordpressPost.edges.length
    ? data.allWordpressPost.edges
    : null

  const isCaseStudy =
    post.categories.filter(category => category.slug === 'case-study').length >
    0

  return (
    <Layout>
      <SEO title={post.title} description={post.acf.meta_description || ''} />

      <Boom backgroundImage={backgroundImage(post)}>
        <div className="overlay">
          <div className="inner">
            <h1>{post.title}</h1>
          </div>
        </div>
      </Boom>
      <Breadcrumb>
        <Link to="/">Home</Link> <Arrow />{' '}
        {isCaseStudy && (
          <>
            <Link to="/case_studies">Case Studies</Link> <Arrow />
          </>
        )}
        <b style={{ minWidth: 200 }}>{post.title}</b>
      </Breadcrumb>
      <Wrapper>
        <div id="top">
          {post.categories.filter(category => category.name === 'slider')
            .length > 0 && (
            <NextFeature>
              {relatedPostsMarkup.map(({ node }) => (
                <SingleFeature
                  to={node.slug}
                  key={node.id}
                  className={node.id === post.id && 'active'}
                >
                  <p className="title">{node.title}</p>
                  <span dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                </SingleFeature>
              ))}
            </NextFeature>
          )}

          <Content
            className="content"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />

          {isCaseStudy && (
            <ReadMore to="/case_studies">
              <div className="title">
                Read More <Arrow />
              </div>
              <span>Read more of our case studies</span>
            </ReadMore>
          )}
        </div>
      </Wrapper>
    </Layout>
  )
}

export const postQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      slug

      acf {
        meta_description
      }

      categories {
        id
        name
        slug
      }

      featured_media {
        id
        source_url
        localFile {
          id
          childImageSharp {
            id
            fluid(maxHeight: 1200) {
              base64
              tracedSVG
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }

    allWordpressPost(
      filter: { categories: { elemMatch: { slug: { eq: "slider" } } } }
    ) {
      edges {
        node {
          id

          title
          excerpt

          slug
        }
      }
    }
  }
`

const ReadMore = styled(Link)`
  background: #141b22;
  padding: 2rem;
  border-radius: 3px;
  width: 100%;
  display: block;
  text-decoration: none;
  color: #fff;

  .title {
    font-size: 18px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
  }

  span {
    margin: 0;
    padding: 0;

    font-size: 13px;

    p {
      margin: 0;
      padding: 0;
    }
  }
`

const NextFeature = styled.div`
  display: flex;
  margin-bottom: 3rem;
  justify-content: space-between;

  width: 115%;
  margin-left: -7.5%;
`
const SingleFeature = styled(Link)`
  flex: 1;
  position: relative;
  padding: 0 1rem;
  margin: 0;
  margin-right: 10px;

  padding-left: 5%;

  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #e9e9e9;

  color: inherit;
  text-decoration: none;

  .title {
    font-size: 18px;
    color: #141b22;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
  }

  span {
    margin: 0;
    padding: 0;

    font-size: 13px;

    p {
      margin: 0;
      padding: 0;
    }
  }

  :before {
    content: '';
    position: absolute;
    right: -35px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 35px solid #e9e9e9;
    border-top: 75px solid transparent;
    border-bottom: 75px solid transparent;
  }

  :after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 35px solid white;
    border-top: 75px solid transparent;
    border-bottom: 75px solid transparent;
  }

  &.active {
    background: #141b22;
    color: #aaaaaa;
    .title {
      color: #fff;
    }

    :before {
      border-left-color: #1b1b22;
    }
  }

  :nth-of-type(1) {
    z-index: 3;
  }

  :nth-of-type(2) {
    z-index: 2;
  }

  :nth-of-type(3) {
    z-index: 1;
  }
`
